import React, { useRef, useState, useEffect } from "react";
import useForm from '../../customHooks/useForm';
import {
  Grid, Typography, IconButton, Divider, Link, Card, InputAdornment, CardMedia
  , ButtonBase, Backdrop, CircularProgress
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { Paid } from '@mui/icons-material';
import SimpleReactValidator from 'simple-react-validator';
import ReauthenticateDialog from "../../components/Dialogs/ReauthenticateDialog";

import CardTemplate from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useForceUpdate, toWithOutError, truncateFloat, formatAllNumber } from 'utils';
import ProgressButton from 'components/CustomButtons/ProgressButton';
import TextFieldWithInfo from 'components/TextField/TextFieldWithInfo';
import { fugaGreen } from "variables/colors";
import BasicCheckbox from 'components/Checkbox/BasicCheckbox';
import { DEPOSITO, mediosDePagoWithInfo } from '../../variables/financial';
import { checkPayoutOTPDocServer, createPayoutOTPDocServer, getUserAvailableMoney } from "services/BackendCommunication";
import useWindowDimensions from '../../customHooks/useWindowDimensions';
import { payoutCreateRequestRedux } from "redux/actions/PayoutsActions";
import InfoActionDialog from 'components/Dialogs/InfoActionDialog';
import { emailsNoEquals, payoutGenerated, payoutLessThanTen, taxesWarning } from '../../utils/textToShow.utils';
import SuccessDialog from 'components/Dialogs/SuccessDialog';
import { useNavigate } from 'react-router-dom';
import { userIsAdmin } from 'utils/users.utils';
import { checkPayoutFormValidations } from "utils/payouts.utils";
import TypographyWithInfo from 'components/Typography/TypographyWithInfo';
import CheckboxWithInfo from 'components/Checkbox/CheckboxWithInfo';
import { discountsCreateRedux } from "redux/actions/DiscountsActions";
import { discountModelForRoyaltyPayout } from "factory/discounts.factory";
import { getPlanesLaFlota, getSystemUsdToArsRatio, getUserDataByEmailInFS } from "services/FirestoreServices";
import { v4 as uuidv4 } from 'uuid';
import TwoAuthPayout from "./TwoAuthPayout";
import RefreshIcon from '@mui/icons-material/Refresh';

const PayoutForm = () => {

  const validator = useRef(new SimpleReactValidator());
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowDimensions = useWindowDimensions();
  let windowWidth = windowDimensions.width;
  let cardMediaWidth = windowWidth > 1427 ? 120 : 80;

  const userData = useSelector(store => store.userData);
  const isAdmin = userIsAdmin(userData.rol);
  const minimumPlanArs = 36500;
  const errorHandler = useSelector(store => store.errorHandler);

  const [openAlertDialog, setOpenAlertDialog] = useState({ open: false, title: "", text: [""] });
  const [openChangeCredentialsDialog, setOpenChangeCredentialsDialog] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [medioDePago, setMedioDePago] = useState({ name: "ARS", currency: "ars", account: 'bank' });
  const [confirmAccountValue, setConfirmAccountValue] = useState("");
  const [ownerAccount, setOwnerAccount] = useState(true);
  const [usdToArsRate, setUsdToArsRate] = useState("");
  const [planPriceInArs, setPlanPriceInArs] = useState(0);
  const [totalRoyaltiesAndPayed, setTotalRoyaltiesAndPayed] = useState({ royalties: 0, payed: 0, loading: true, available: 0, taxesUsd: 0 });
  const [cuponName, setCuponName] = useState("");
  const [laFlotaPlanes, setLaFlotaPlanes] = useState("");

  const [editState, setEditState] = useState("none");
  const [buttonText, setButtonText] = useState("Solicitar Regalías");

  const [userToAttachPayout, setUserToAttachPayout] = useState("");
  const [emailTarget, setEmailTarget] = useState("");
  const [refreshData, setRefreshData] = useState('not-clicked');

  const defaultOtpInfoDialog = { open: false, payout: {} };
  const [otpDialogInfo, setOtpDialogInfo] = useState(defaultOtpInfoDialog);

  const bankOption = medioDePago.account === "bank";
  const paypalOption = medioDePago.account === "paypal";
  const cuponOption = medioDePago.account === 'cupon';
  const wiseOption = medioDePago.account === "wise";

  let defaultData = {
    userName: userData.nombre || "",
    userLastName: userData.apellido || "",
    telefono: userData.telefono || "",
    ciudad: userData.ciudad || "",
    userCuit: userData.cuit,
    ownerEmail: userData.email,
    stats: userData.stats,
    ownerId: userData.id,
    paypalEmail: "",
    payoneerEmail: "",
    cbuCvuAlias: "",
    transferTotalUsd: 0,
    cupon: false,
    paymentMethod: DEPOSITO,
    id: uuidv4()
  };

  const [autoRenewPayout, setAutoRenewPayout] = useState(defaultData);

  useEffect(() => {
    const getUsdToArsRate = async () => {
      const usdToArs = await getSystemUsdToArsRatio(dispatch);
      setUsdToArsRate(usdToArs);
    }
    getUsdToArsRate();
  }, []);

  useEffect(() => {
    const getLaFlotaPlanes = async () => {
      const planesLaFlota = await getPlanesLaFlota(dispatch);
      setLaFlotaPlanes(planesLaFlota.filter(plan => plan.status === 'available'));
    }
    getLaFlotaPlanes();
  }, []);

  useEffect(() => {
    if (!isAdmin && Array.isArray(laFlotaPlanes)) {
      const userPlanData = laFlotaPlanes.find(plan => plan.id === userData.plan);
      setPlanPriceInArs(userPlanData?.priceArs || minimumPlanArs);
    }
  }, [laFlotaPlanes])

  useEffect(() => {
    const getTargetUserByEmail = async () => {
      const userTargetData = await getUserDataByEmailInFS(emailTarget, dispatch);
      if (["ERROR", "EMPTY"].includes(userTargetData)) return "ERROR";
      setUserToAttachPayout(userTargetData);
      setMedioDePago({ name: "ARS", currency: "ars", account: 'cupon' });
      setAutoRenewPayout({
        ...autoRenewPayout, ownerEmail: userTargetData.email, userLastName: userTargetData.apellido,
        ownerId: userTargetData.id, userName: userTargetData.nombre
      });
    }
    if (isAdmin && emailTarget) getTargetUserByEmail();
  }, [refreshData]);

  useEffect(() => {
    const getUserFinancialInfo = async () => {
      const { payed, royalties, available, lastRequest, taxesUsd } = await getUserAvailableMoney(userData, dispatch);
      setTotalRoyaltiesAndPayed({ payed, royalties, loading: false, available, lastRequest, taxesUsd });
      setForm({ target: { name: 'transferTotalUsd', value: available } });
    }
    if (!isAdmin && userData.id) getUserFinancialInfo();
  }, [usdToArsRate]);

  useEffect(() => {
    const getTargetUserFinancial = async () => {
      const { payed, royalties, available, lastRequest, taxesUsd } = await getUserAvailableMoney(userToAttachPayout, dispatch);
      setTotalRoyaltiesAndPayed({ payed, royalties, loading: false, available, lastRequest, taxesUsd });
      const userPlanData = laFlotaPlanes.find(plan => plan.id === userToAttachPayout.plan) || { priceArs: minimumPlanArs };
      const planInUsdForCupon = Math.ceil((userPlanData.priceArs / usdToArsRate) * 100) / 100;
      const planInArs = Math.ceil((planInUsdForCupon * usdToArsRate) * 100) / 100;
      setPlanPriceInArs(planInArs);
      setForm({ target: { name: 'transferTotalUsd', value: planInUsdForCupon } });
    }
    if (isAdmin && usdToArsRate > 0 && userToAttachPayout.id && laFlotaPlanes) getTargetUserFinancial();
  }, [userToAttachPayout, usdToArsRate]);

  const [formData, setForm] = useForm(defaultData);
  let { userName, userLastName, userCuit, telefono, paypalEmail, payoneerEmail, cbuCvuAlias, transferTotalUsd } = formData;

  const handleCloseAlertDialog = () => setOpenAlertDialog({ open: false, title: "", text: [""] });

  const handleSubmit = async () => {
    let payoutSettedData = isAdmin ? autoRenewPayout : formData;
    let resultEdit = await toWithOutError(dispatch(payoutCreateRequestRedux({
      ...payoutSettedData, currency: medioDePago.currency.toUpperCase(),
      currencyRateToUsd: medioDePago.currency === "ars" ? usdToArsRate : 1,
      transferTotalAskedCurrency: medioDePago.currency === "ars"
        ? truncateFloat(parseFloat(transferTotalUsd) * parseFloat(usdToArsRate), 2, '.') : 0,
      alreadyPaidUsd: parseFloat(totalRoyaltiesAndPayed.payed),
      historicTotalUsd: truncateFloat(parseFloat(totalRoyaltiesAndPayed.payed) + parseFloat(transferTotalUsd)),
      cupon: cuponOption
    }, isAdmin)));

    if (resultEdit?.payout?.cupon) {
      let discountFromPayout = discountModelForRoyaltyPayout(resultEdit.payout, isAdmin);
      resultEdit = await toWithOutError(dispatch(discountsCreateRedux(discountFromPayout)))
      if (resultEdit === "SUCCESS") setCuponName(discountFromPayout.name);
    }
    setEditState(resultEdit.result ? resultEdit.result : resultEdit);
  }

  const accountValuesAreNotEquals = confirmValue => {
    if (bankOption) return confirmValue !== cbuCvuAlias;
    if (paypalOption) return confirmValue !== paypalEmail;
    if (wiseOption) return confirmValue !== payoneerEmail;
    return false;
  }

  const checkFields = () => {
    forceUpdate();
    if (!transferTotalIsValid()) {
      setOpenAlertDialog({ open: true, title: helperTextAvailable(), text: payoutLessThanTen(isAdmin ? userToAttachPayout.plan : userData.plan) }); return false;
    }
    if (isAdmin) return true;
    if (accountValuesAreNotEquals(confirmAccountValue)) {
      setOpenAlertDialog({ open: true, title: "Los emails no coinciden.", text: emailsNoEquals }); return false;
    }
    if (checkPayoutFormValidations(medioDePago, validator)) return true;
    else {
      validator.current.showMessages();
      forceUpdate();
      return false;
    }
  }

  const handleVerifiedOTP = async (payoutId, otp) => {
    let result = await toWithOutError(checkPayoutOTPDocServer(payoutId, otp, dispatch));
    return result;
  }

  const handleCreateOTP = async () => {
    setOpenLoader(true);
    if (checkFields()) {
      let result = await toWithOutError(createPayoutOTPDocServer(formData.id, userData.id, dispatch));
      if (result === "NEW_PAYOUT_OTP") setOtpDialogInfo({ open: true, payout: formData });
      if (result === "RESEND_OLD_PAYOUT_OTP") setOtpDialogInfo({ open: true, payout: formData });
      if (["ERROR", "ERROR_CREATING_OTP"].includes(result)) { }
    }
    setOpenLoader(false);
  };

  const handleCreatePayoutFromAdmin = () => {
    setOpenLoader(true);
    if (checkFields()) handleSubmit();
    setOpenLoader(false);
  }

  const handleCloseOtpDialog = () => setOtpDialogInfo(defaultOtpInfoDialog);

  useEffect(() => {
    if (editState === "SUCCESS") setButtonText("Regalías Solicitadas");
  }, [editState, errorHandler]);

  const cuitHelperLink = <Link sx={{ fontSize: '0.75rem' }} href="https://www.cuitonline.com/" target="_blank" variant="body2" underline="hover">
    Si no lo sabés hacé click acá.
  </Link>

  let borderHeight = 7;
  const cardStylePesos = {
    width: medioDePago.currency === "ars" ? cardMediaWidth - borderHeight * 2 : cardMediaWidth,
    height: medioDePago.currency === "ars" ? cardMediaWidth - borderHeight * 2 : cardMediaWidth,
    borderRadius: "1em", border: medioDePago.currency === "ars" ? 7 : 0, borderColor: fugaGreen,
  };

  const cardStyleUsd = {
    width: medioDePago.currency === "usd" ? cardMediaWidth - borderHeight * 2 : cardMediaWidth,
    height: medioDePago.currency === "usd" ? cardMediaWidth - borderHeight * 2 : cardMediaWidth,
    borderRadius: "1em", border: medioDePago.currency === "usd" ? 7 : 0, borderColor: fugaGreen,
  };

  const onClickCurrency = currency => {
    setMedioDePago({
      name: currency === "dolar" ? "USD" : "ARS",
      currency: currency === "dolar" ? "usd" : "ars",
      account: currency === "dolar" ? "paypal" : "bank",
      accountName: currency === "dolar" ? "PayPal" : "bank",
    })
  }

  const onCheckMedioDePago = medioDePagoName => {
    setMedioDePago({ ...medioDePago, account: medioDePagoName.id, accountName: medioDePagoName.label });
    if (medioDePagoName.id === 'cupon' && !isAdmin) {
      const planInUsd = Math.ceil((planPriceInArs / usdToArsRate) * 100) / 100;
      setForm({ target: { name: 'transferTotalUsd', value: Math.min(planInUsd, totalRoyaltiesAndPayed.available) } });
    }
  }
  const handleConfirmAccountValue = event => setConfirmAccountValue(event.target.value);
  const onCheckOwnerAccount = checked => setOwnerAccount(checked);

  const handleCbu = event => {
    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    setForm({ target: { name: 'cbuCvuAlias', value: onlyNums } });
  }

  const handleUsdToWithdraw = event => {
    const regex = /^\d*([.,]\d*)?$/;
    const onlyNums = event.target.value;
    if (regex.test(onlyNums)) {
      setForm({ target: { name: 'transferTotalUsd', value: onlyNums.replace(',', '.') } });
      if (isAdmin) setAutoRenewPayout({ ...autoRenewPayout, transferTotalUsd: onlyNums.replace(',', '.') })
    }
  }

  const handleGoToRetiros = () => navigate("/admin/retiros");

  const helperTextAvailable = () => {
    if (transferTotalUsd < 1 && medioDePago.account === "cupon") return "Si elegís cupón de crédito para pagar tu suscripción el monto no debe ser menor a 1 USD";
    if (transferTotalUsd < 10 && medioDePago.account === "cupon") return "Ingresa la cantidad de Dólares (USD) a retirar.";
    if ((transferTotalUsd > (usdToArsRate * planPriceInArs + 0.1)) && medioDePago.account === "cupon") return `Si elegís cupón de crédito para pagar tu suscripción el monto debe ser menor al precio de tu suscripción: ${planPriceInArs} ARS.`;
    if (transferTotalUsd > totalRoyaltiesAndPayed.available) return "El monto ingresado es mayor al monto disponible."
    if (transferTotalUsd < 10) return "No podés retirar un monto menor a 10 USD";
    return "Ingresa la cantidad de Dólares (USD) a retirar.";
  }

  const transferTotalIsValid = () => {
    if (transferTotalUsd > totalRoyaltiesAndPayed.available) return false;
    if (medioDePago.account === "cupon" && (transferTotalUsd < 1 || (transferTotalUsd > (usdToArsRate * planPriceInArs + 0.1)))) return false;
    if (transferTotalUsd < 10 && medioDePago.account !== "cupon") return false;
    return true;
  }

  const taxesTitle = [
    `Disponible: ${formatAllNumber(totalRoyaltiesAndPayed.available, 2, '.')} USD`,
    <br />,
    `Impuestos: ${formatAllNumber(totalRoyaltiesAndPayed.taxesUsd, 2, '.')} USD`
  ]

  const onlyTaxesTitle = [
    `Impuestos: ${formatAllNumber(totalRoyaltiesAndPayed.taxesUsd, 2, '.')} USD`
  ]

  const hasNoTaxes = totalRoyaltiesAndPayed.taxesUsd === 0;

  const changeOwnerEmail = event => setEmailTarget(event.target.value);
  const handleRefresh = () => setRefreshData('clicked');

  return (
    <Grid container justifyContent="center">

      <Backdrop open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <TwoAuthPayout isOpen={otpDialogInfo.open} handleOk={handleSubmit} handleClose={handleCloseOtpDialog}
        handleVerifiedOTP={handleVerifiedOTP} payout={formData} />

      <ReauthenticateDialog isOpen={openChangeCredentialsDialog} setIsOpen={setOpenChangeCredentialsDialog}
        textName={userData.nombre} />

      <SuccessDialog isOpen={editState === 'SUCCESS'} title={"¡Tu solicitud se ha procesado correctamente!"}
        contentTexts={payoutGenerated(medioDePago.account, cuponName)} handleClose={handleGoToRetiros}
        successImageSource="/images/success.jpg" size="sm" />

      <InfoActionDialog id='alert-payout' isOpen={openAlertDialog.open} handleClose={handleCloseAlertDialog}
        title={openAlertDialog.title} contentTexts={openAlertDialog.text} />

      <Grid item xs={12} md={11} lg={10}>
        <CardTemplate>

          <CardHeader color="primary" style={{ background: fugaGreen, textAlign: "center" }}>
            <Typography sx={cardTitleWhiteStyles}>Solicitar Regalías</Typography>
            <p style={cardCategoryWhiteStyles}>Todos los datos que proporciones deben ser correctos, por favor revisa el formulario antes de enviarlo.</p>
            <p style={cardCategoryWhiteStyles}>Las transferencias se realizan durante los próximos 10 días hábiles.</p>
            <p style={cardCategoryWhiteStyles}>Si solicitas un cupón de crédito, el mismo te llegará por email.</p>
            <p style={cardCategoryWhiteStyles}>Los reportes se cargan el 15 de cada mes.</p>
          </CardHeader>

          <CardBody>
            <Grid container justifyContent="center">

              {isAdmin && <Grid container item xs={6} alignItems="center" spacing={1}>
                <Grid item xs={11} textAlign="center">
                  <TextFieldWithInfo
                    name="email"
                    required
                    fullWidth
                    sx={{ width: '100%' }}
                    label="Email del dueño del Retiro"
                    value={emailTarget}
                    onChange={changeOwnerEmail}
                    validatorProps={{ restrictions: 'email|max:100', message: "Debes ingresar un email.", validator: validator }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={handleRefresh}>
                    <RefreshIcon sx={{ fontSize: '2rem' }} />
                  </IconButton>
                </Grid>
              </Grid>}

              <Grid item xs={12} textAlign="center" paddingTop={2}>
                <Typography sx={lastRequestTextStyle} >
                  {`Última Solicitud: ${totalRoyaltiesAndPayed.loading
                    ? "..." : totalRoyaltiesAndPayed.lastRequest || "no tienes solicitudes."}`}
                </Typography>
              </Grid>

              <Grid item xs={4} textAlign="center" paddingTop={2}>
                <Typography sx={moneyTextStyle} >
                  {`Regalías Totales: ${totalRoyaltiesAndPayed.loading
                    ? "... USD"
                    : formatAllNumber(totalRoyaltiesAndPayed.royalties, 2, '.') + " USD"}`}
                </Typography>
              </Grid>

              <Grid item xs={4} textAlign="center" paddingTop={2}>
                <Typography sx={moneyTextStyle} >
                  {`Regalías ya Solicitadas: ${totalRoyaltiesAndPayed.loading
                    ? "... USD"
                    : formatAllNumber(totalRoyaltiesAndPayed.payed, 2, '.') + " USD"}`}</Typography>
              </Grid>

              {!hasNoTaxes && <Grid item xs={4} textAlign="center" paddingTop={2}>
                <TypographyWithInfo
                  infoDialog={taxesWarning}
                  title={`Impuestos: ${formatAllNumber(totalRoyaltiesAndPayed.taxesUsd, 2, '.')} USD`}
                  dialogTitle={onlyTaxesTitle}
                  sx={moneyTextStyle}
                  infoTooltip="Haz click para ver más información."
                  containerSize={12}
                  infoSx={{ marginTop: '-5px' }} />
              </Grid>}

              <Grid item xs={4}
                sx={{ marginLeft: hasNoTaxes ? '0px' : '0px' }}
                textAlign="center" paddingBottom={2} paddingTop={2}>
                {hasNoTaxes
                  ? <Typography sx={moneyTextStyle} >
                    {`Disponible a Retirar: ${formatAllNumber(totalRoyaltiesAndPayed.available, 2, '.')} USD`}
                  </Typography>

                  : <TypographyWithInfo
                    infoDialog={taxesWarning}
                    title={`Disponible a Retirar: ${formatAllNumber(totalRoyaltiesAndPayed.available, 2, '.')} USD`}
                    dialogTitle={taxesTitle}
                    sx={moneyTextStyle}
                    infoTooltip="Haz click para ver más información."
                    containerSize={12}
                    infoSx={{ marginTop: '-5px' }} />}
              </Grid>

              <Grid item xs={12} textAlign='center'>
                <TextFieldWithInfo
                  name="transferTotalUsd"
                  required
                  sx={{ width: '300px' }}
                  disabled={totalRoyaltiesAndPayed.available < 10}
                  error={!transferTotalIsValid()}
                  label="Dolares a Retirar"
                  helperTextDown={helperTextAvailable()}
                  value={transferTotalUsd}
                  onChange={handleUsdToWithdraw}
                  startAdormentObject={<InputAdornment position="start">USD</InputAdornment>}
                  validatorProps={{
                    restrictions: 'required|numeric',
                    message: "Ingresa un monto válido, debe ser menor que la cantidad de regalías disponibles.", validator: validator
                  }}
                />
              </Grid>

              <Grid item xs={12} textAlign="-moz-center" paddingTop={2} paddingBottom={2}>
                <Divider sx={{ borderWidth: '3px' }} />
              </Grid>

              <Grid item xs={8} textAlign="center" paddingBottom={2}>
                <Typography sx={subtitlesStyles} >{`Moneda de retiro: ${medioDePago.currency === 'ars' ? "ARS" : "USD"}`}</Typography>
              </Grid>

              <Grid item xs={4} textAlign="center" paddingBottom={2}>
                <Typography sx={subtitlesStyles} >Medio de pago</Typography>
              </Grid>

              <Grid container>
                <Grid item xs={4} textAlign="-moz-center">
                  <ButtonBase>
                    <Card sx={cardStylePesos} raised={true}>
                      <CardMedia
                        onClick={() => onClickCurrency('pesos')}
                        sx={{ height: "100%" }}
                        component="img"
                        height={cardMediaWidth}
                        image={'/images/pesos.jpg'}
                        alt="pesos"
                      />
                    </Card>
                  </ButtonBase>
                </Grid>

                <Grid item xs={4} textAlign="-moz-center">
                  <ButtonBase>
                    <Card sx={cardStyleUsd} raised={true}>
                      <CardMedia
                        onClick={() => onClickCurrency('dolar')}
                        sx={{ height: "100%" }}
                        component="img"
                        height={cardMediaWidth}
                        image={'/images/dolar.png'}
                        alt="dolares"
                      />
                    </Card>
                  </ButtonBase>

                </Grid>

                <Grid container item xs={4} sx={{ height: '100px' }}>
                  {mediosDePagoWithInfo.filter(mp => mp.currency === medioDePago.currency).map(medioDePagoConInfo =>
                    <Grid item xs={12} textAlign="left" key={medioDePagoConInfo.id}>
                      <CheckboxWithInfo key={medioDePagoConInfo.id}
                        checked={medioDePago.account === medioDePagoConInfo.id}
                        onChecked={() => onCheckMedioDePago(medioDePagoConInfo)}
                        label={medioDePagoConInfo.label}
                        checkBoxHelper={medioDePagoConInfo.checkBoxHelper}
                        labelGridSize={medioDePagoConInfo.checkBoxHelper ? windowWidth > 1427 ? 3 : 8 : 12}
                        labelTextAlign="start"
                        color={fugaGreen} />
                    </Grid>
                  )}

                </Grid>
              </Grid>

              <Grid item xs={12} textAlign="-moz-center" paddingTop={3} paddingBottom={2}>
                <Divider sx={{ borderWidth: '3px' }} />
              </Grid>

              {!cuponOption &&
                <Grid item xs={12} textAlign="center" paddingBottom={2}>
                  <Typography sx={subtitlesStyles} >Datos de la Cuenta</Typography>
                </Grid>
              }

              {medioDePago.currency === "usd" &&
                <Grid container item >
                  <Grid item xs={6} paddingRight={2}>
                    <TextFieldWithInfo
                      fullWidth
                      name={paypalOption ? "paypalEmail" : "payoneerEmail"}
                      required
                      label={`Email de ${medioDePago.accountName}`}
                      helperTextDown={`Ingresá el email de tu cuenta de ${medioDePago.accountName} a donde recibirás tus regalías.`}
                      value={paypalOption ? paypalEmail : payoneerEmail}
                      onChange={setForm}
                      validatorProps={{ restrictions: 'required|email', message: "Ingresa un email válido.", validator: validator }}
                    />
                  </Grid>
                  <Grid item xs={6} paddingLeft={2}>
                    <TextFieldWithInfo
                      fullWidth
                      required
                      error={confirmAccountValue !== (paypalOption ? paypalEmail : payoneerEmail)}
                      name="confirmEmail"
                      label={`Confirmar Email de ${medioDePago.accountName}`}
                      value={confirmAccountValue}
                      helperTextDown="Confirma el Email."
                      onChange={handleConfirmAccountValue}
                      validatorProps={{ restrictions: 'required|email', message: "Ingresa un email válido", validator: validator }}
                    />
                  </Grid>
                </Grid>}

              {bankOption &&
                <>
                  <Grid item xs={6} textAlign="center">
                    <BasicCheckbox
                      label={"La cuenta me pertenece"}
                      onChecked={(event) => onCheckOwnerAccount(event.target.checked)}
                      checked={ownerAccount}
                      color={fugaGreen}
                    />
                  </Grid>
                  <Grid item xs={6} textAlign="center">
                    <BasicCheckbox
                      label={"La cuenta es de otra persona"}
                      onChecked={(event) => onCheckOwnerAccount(!event.target.checked)}
                      checked={!ownerAccount}
                      color={fugaGreen}
                    />
                  </Grid>

                  <Grid item xs={6} paddingRight={2}>
                    <TextFieldWithInfo
                      fullWidth
                      name="cbuCvuAlias"
                      required
                      label="CBU/CVU"
                      helperTextDown="Ingresá el CBU/CVU de la Cuenta Bancaria donde enviaremos el dinero. Son 22 números."
                      value={cbuCvuAlias}
                      onChange={handleCbu}
                      validatorProps={{
                        restrictions: bankOption ? 'required|numeric|min:22|max:22' : "",
                        message: "Ingresa un CBU/CVU válido, debe tener 22 números.", validator: validator
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} paddingRight={2}>
                    <TextFieldWithInfo
                      fullWidth
                      error={confirmAccountValue !== cbuCvuAlias}
                      name="cbuCvuAlias"
                      required
                      helperTextDown={confirmAccountValue !== cbuCvuAlias ? "Los CBU/CVU no coinciden." : ""}
                      label="Confirmar CBU/CVU"
                      value={confirmAccountValue}
                      onChange={handleConfirmAccountValue}
                      validatorProps={{
                        restrictions: bankOption ? 'required|numeric|min:22|max:22' : "",
                        message: "Ingresa un CBU/CVU válido, debe tener 22 números.", validator: validator
                      }}
                    />
                  </Grid>
                </>
              }

              {(bankOption || cuponOption) &&
                <Grid paddingTop={cuponOption ? 2 : 0}>
                  <Grid item xs={12} textAlign="center" paddingBottom={2}>
                    <Typography sx={moneyTextStyle} >{`Cotización USD/ARS: 
                                ${formatAllNumber(usdToArsRate, 2, '.')}`}</Typography>
                  </Grid>

                  <Grid item xs={12} textAlign="center" paddingBottom={2}>
                    <Typography sx={moneyTextStyle} >{`Retiro en Pesos: 
                                ${formatAllNumber(parseFloat(transferTotalUsd) * parseFloat(usdToArsRate), 2, '.')}`}</Typography>
                  </Grid>
                </Grid>
              }

              <Grid item xs={12} textAlign="-moz-center" paddingTop={3} paddingBottom={2}>
                <Divider sx={{ borderWidth: '3px' }} />
              </Grid>

              {!isAdmin && <Grid container item>

                <Grid item xs={12} textAlign="center" paddingBottom={2}>
                  <Typography sx={subtitlesStyles} >Datos del Solicitante</Typography>
                </Grid>

                <Grid item xs={6} paddingRight={2}>
                  <TextFieldWithInfo
                    fullWidth
                    name="userName"
                    required={!isAdmin}
                    label="Nombre del Solicitante"
                    helperTextDown="Nombre del dueño de la cuenta donde recibirá el dinero."
                    value={isAdmin ? (userToAttachPayout.nombre || "") : userName}
                    onChange={setForm}
                    validatorProps={{ restrictions: 'required|min:1|max:100', message: "Ingresa un nombre.", validator: validator }}
                  />
                </Grid>
                <Grid item xs={6} paddingLeft={2}>
                  <TextFieldWithInfo
                    fullWidth
                    required={!isAdmin}
                    name="userLastName"
                    label="Apellido"
                    value={isAdmin ? (userToAttachPayout.apellido || "") : userLastName}
                    helperTextDown="Apellido del dueño de la cuenta donde recibirá el dinero."
                    onChange={setForm}
                    validatorProps={{ restrictions: 'required|min:1|max:100', message: "Ingresa un apellido.", validator: validator }}
                  />
                </Grid>
              </Grid>}

              {!isAdmin && <Grid container item>
                <Grid item xs={6} paddingRight={2}>
                  <TextFieldWithInfo
                    fullWidth
                    required={!isAdmin}
                    name="telefono"
                    label="Celular de Contacto"
                    value={telefono}
                    placeholder="+549115771715"
                    helperTextDown="+549 + (código de área) + N° celular"
                    onChange={setForm}
                    validatorProps={{ restrictions: 'required|min:1|max:20', message: "Ingresa un número de celular válido.", validator: validator }}
                  />
                </Grid>
                {bankOption && <Grid item xs={6} paddingLeft={2}>
                  <TextFieldWithInfo
                    fullWidth
                    required
                    name="userCuit"
                    label="CUIT"
                    value={userCuit}
                    placeholder="20-12345678-0"
                    helperTextDown={cuitHelperLink}
                    onChange={setForm}
                    validatorProps={{ restrictions: bankOption ? 'required' : "", message: "Ingresa un número de CUIT válido.", validator: validator }}
                  />
                </Grid>}
              </Grid>}

            </Grid>
          </CardBody>

          {((isAdmin && userToAttachPayout.id) || !isAdmin) && <CardFooter>
            <Grid container item xs={12} justifyContent="center" spacing={2}>
              <ProgressButton
                textButton={buttonText}
                loading={openLoader}
                buttonState={editState.toLowerCase()}
                onClickHandler={isAdmin ? handleCreatePayoutFromAdmin : handleCreateOTP}
                disabled={false}
                noneIcon={<Paid />}
                color="secondary"
                backgroundColor={fugaGreen}
                noFab={false} />
            </Grid>
          </CardFooter>}

        </CardTemplate>
      </Grid>

    </Grid>
  );
}

export default PayoutForm;

const cardCategoryWhiteStyles = { color: "rgba(255,255,255,.82)", margin: "0 0 0", fontSize: "14px", fontWeight: "400" }
const subtitlesStyles = { fontSize: "30px", fontWeight: "bold", color: fugaGreen };
const moneyTextStyle = { fontSize: "18px", fontWeight: 400 };
const lastRequestTextStyle = { fontSize: "22px", fontWeight: 500 };
const cardTitleWhiteStyles = {
  color: "rgba(255,255,255,255)",
  marginTop: "0px",
  minHeight: "auto",
  fontWeight: "300px",
  fontSize: "40px",
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  marginBottom: "3px",
  textDecoration: "none"
}
